<template functional>
  <div class="aside-panel__wrapper" />
</template>

<script>

export default {
  name: 'AppDefaultAsideRight',
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
